<template>
  <div>
    <div class="text-3xl">南京松果时光科技有限公司</div>
    <div class="text-base about-content">
      <p>公司拥有强大的技术实力和创新能力，为用户创造价值。</p>
      <p>致力于为用户打造优质、有趣、多元化的社交体验，让用户在社交娱乐平台中尽情享受快乐和交流</p>
      <p>我们坚持以用户为中心，不断推陈出新，追求卓越，努力实现让每一个用户都感到满意和幸福的目标</p>
      <p>我们相信，只有为用户创造价值，才能获得用户的信任和支持</p>
    </div>
  </div>
</template>

<script>
</script>

<style scoped>
.about-content {
  margin: 2rem 0 5rem;
}
</style>