<template>
  <div class="app-root" :class="{mobile}">
    <header>
      <div class="app-row">
        <el-menu :default-active="route.path" class="app-menu" mode="horizontal" @select="locate">
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/about">关于我们</el-menu-item>
        </el-menu>
      </div>
    </header>
    <main>
      <router-view class="app-main app-row" :class="{mobile}"></router-view>
    </main>
    <footer>
      <p class="app-row text-xs">
        <span>丨网站备案号:</span>
        <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2024099784号-1</a>
        <span>丨南京松果时光科技有限公司 丨 江苏省南京市鼓楼区石头城81号403</span>
      </p>
    </footer>
  </div>
</template>

<script setup>
import {useRoute} from 'vue-router'
import router from '@/router'
import {onMounted, ref} from 'vue'

const route = useRoute()
const locate = path => router.push(path)

const mobile = ref(false)
const checkChange = q => mobile.value = !!q.matches
onMounted(() => {
  const mediaQuery = window.matchMedia('(max-width: 768px)')
  checkChange(mediaQuery)
  mediaQuery.addListener(checkChange)
})
</script>