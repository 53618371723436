<template>
  <div class="h-full flex justify-evenly items-center">
    <el-carousel class="carousel" trigger="click" height="auto" type="card" :interval="40000">
      <el-carousel-item class="carousel-item" v-for="(image, index) in images" :key="index">
        <img :src="image" alt="carousel image">
      </el-carousel-item>
    </el-carousel>
    <div class="product-intro">
      <div class="flex flex-row justify-between items-center product-title">
        <div class="text-5xl font-bold">
          <span>6070俱乐部</span>
        </div>
        <el-button type="primary" size="default" @click="openApp">下载应用</el-button>
      </div>
      <div class="text-base product-desc">
        <p>「6070 俱乐部」是一款专注于服务退休和准退人群的文化社区产品，搭建了汉字、成语、文学、历史等主题分类，为目标群体提供知识学习、分享、展示和交流服务，解决中老年人价值感缺失等问题，让退休生活更加丰富多彩。</p>
      </div>
    </div>
  </div>

</template>

<script setup>
import img1 from '@/assets/1.png'
import img2 from '@/assets/2.png'
import img3 from '@/assets/3.png'
import img4 from '@/assets/4.png'
import img5 from '@/assets/5.png'
import img6 from '@/assets/6.png'

const images = [img1, img2, img3, img4, img5, img6]

function openApp() {
  window.open('https://down.6070club.com/apk/6070club-1.6.0-android_normal-release.apk')
}

</script>

<style scoped>

.app-main {
  padding: 1rem 0;
}

.app-main:not(.mobile) .product-intro {
  text-align: left;
  width: 40%;
}

.product-title {
  margin: 2rem 1rem;
}

.product-desc {
  color: hsla(0, 0%, 100%, .5);
}

.carousel {
  width: 100%;
  max-width: 38.4rem;
  height: 43.3rem;
}

.carousel-item {
  width: 18.47rem; /*   0.461822660098522 * height */
  height: 40rem;
  background-color: #fff;
  border: 0.4rem solid #fff;
  border-radius: 1.6rem;
  box-shadow: 0 0 1rem #00000066;
  overflow: hidden;
}

.carousel img {
  width: 100%;
  height: 100%;
}

.carousel-item:not(.is-in-stage) {
  opacity: 0;
}
</style>
