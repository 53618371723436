import {createApp} from 'vue'
import App from './app.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './styles/common.css'
import './styles/primitive.css'

createApp(App)
  .use(router)
  .use(ElementPlus, {size: 'small'})
  .mount('#app')
